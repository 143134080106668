img {
  min-width: 30px;
  min-height: 30px;
  max-width: 60px;
  max-height: 60px;
}


.container {
  display: grid;
  grid-template-columns: 10em 1fr;
  grid-template-rows: auto 1fr auto;
  background-color: lightblue;

}
header {
  grid-column: 1 / span 2;
  grid-row: 1;
  background-color: lightseagreen;
  text-align: center;
}
.nav {



  grid-column: 1;
  grid-row: 2;
 
  background-color: lightpink;
}
.plateauJeu,.but {
  display: flex;

  grid-column: 2;
  grid-row: 2;

  background-color: lightskyblue;
  flex-wrap: wrap;
  margin-left: 10px;
}

footer {
  display: flex;
  grid-column: 1 / span 2;
  grid-row: 3;
  background-color: lightcoral;
  justify-content: center;
  flex-wrap: wrap;
}
#td00,
#td01,
#td02,
#td03,
#td04,
#td10,
#td11,
#td12,
#td13,
#td14,
#td20,
#td21,
#td22,
#td23,
#td24,
#td30,
#td31,
#td32,
#td33,
#td34,
#td40,
#td41,
#td42,
#td43,
#td44 {
  border: solid 1px black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
}
.container2 {
  display: grid;
  grid-template-columns: 30px 30px 30px 30px 30px 30px;
  gap: 20px;
}

#td00 {
  grid-column-start: 1;
  grid-row-start: 1;
}
#td01 {
  grid-column-start: 2;
  grid-row-start: 1;
}
#td02 {
  grid-column-start: 3;
  grid-row-start: 1;
}
#td03 {
  grid-column-start: 4;
  grid-row-start: 1;
}
#td04 {
  grid-column-start: 5;
  grid-row-start: 1;
}

#td10 {
  grid-column-start: 1;
  grid-row-start: 2;
}
#td11 {
  grid-column-start: 2;
  grid-row-start: 2;
}
#td12 {
  grid-column-start: 3;
  grid-row-start: 2;
}
#td13 {
  grid-column-start: 4;
  grid-row-start: 2;
}
#td14 {
  grid-column-start: 5;
  grid-row-start: 2;
}

#td20 {
  grid-column-start: 1;
  grid-row-start: 3;
}
#td21 {
  grid-column-start: 2;
  grid-row-start: 3;
}
#td22 {
  grid-column-start: 3;
  grid-row-start: 3;
}
#td23 {
  grid-column-start: 4;
  grid-row-start: 3;
}
#td24 {
  grid-column-start: 5;
  grid-row-start: 3;
}

#td30 {
  grid-column-start: 1;
  grid-row-start: 4;
}
#td31 {
  grid-column-start: 2;
  grid-row-start: 4;
}
#td32 {
  grid-column-start: 3;
  grid-row-start: 4;
}
#td33 {
  grid-column-start: 4;
  grid-row-start: 4;
}
#td34 {
  grid-column-start: 5;
  grid-row-start: 4;
}

#td40 {
  grid-column-start: 1;
  grid-row-start: 5;
}
#td41 {
  grid-column-start: 2;
  grid-row-start: 5;
}
#td42 {
  grid-column-start: 3;
  grid-row-start: 5;
}
#td43 {
  grid-column-start: 4;
  grid-row-start: 5;
}
#td44 {
  grid-column-start: 5;
  grid-row-start: 5;
}
span {
  color: white;
}
.tir {
    background-color: red;
  }
  button{
    background-color: lightslategrey; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10%;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  input{
    background-color: white; /* Green */
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10%;
    margin-bottom: 20px;
    margin-left: 10px;

  }
  label{margin-left: 10px;}
  div{margin-left: 5px;}